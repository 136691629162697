import * as React from 'react';
import { Flex, Box, Card, Icon } from 'components';
import styled, { ThemeContext } from 'styled-components/macro';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from 'utils/localStorageItemConverter';
import { DRIVERS_MODAL_SHOWN_MAX_COUNT } from 'utils/config';
import { getCustomer } from '../../../ducks/auth/selectors';
import { useReduxAction, useReduxSelector } from 'hooks';
import { getModalsCountKey } from 'pages/drivers/DriversModal';
import { driverDownloadClicked } from 'state/UI';

const H2 = styled.h2`
  color: ${({ theme }) => theme.neutral.darkest};
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 0.4px;
  font-style: normal;
  margin: 0px;
`;
const StyledCard = styled(Card)`
  padding: 16px 24px 19px 16px;
`;
const P = styled.p`
  margin: 4px 0px 16px 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.neutral.darkest};
`;

const StyledLink = styled.a`
  text-decoration: none;
  height: 24px;
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 16px;
  letter-spacing: 0.4px;
  border: none;
  white-space: nowrap;
  outline: none;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-weight: 500;

  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.primary.base};
  transition: color 0.3s, background-color 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.primary.dark};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.primary.lighter};
  }
  &:focus,
  &:not:disabled {
    background-color: ${({ theme }) => theme.primary.dark};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.primary.base};
  }
`;

export const DriversCardComponent = ({ os, info, ...props }): any => {
  const { t } = useTranslation('apps');
  const customer = useReduxSelector(getCustomer);
  const downloadDriver = useReduxAction(driverDownloadClicked);

  const theme = React.useContext(ThemeContext);
  const isMobile = useMediaQuery({ query: '(max-width: 580px)' });
  const browserInfo = navigator.appVersion.toLocaleLowerCase();

  const convert = (os) => {
    if (os === 'Mac') return 'OS X';
    return os;
  };

  const downloadClicked = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    downloadDriver({ os });

    if (
      getFromLocalStorage(getModalsCountKey(customer)) !== null &&
      browserInfo.indexOf(os.toLocaleLowerCase()) !== -1
    ) {
      saveToLocalStorage(
        getModalsCountKey(customer),
        DRIVERS_MODAL_SHOWN_MAX_COUNT,
      );
    }
  };

  return (
    <StyledCard
      width={isMobile ? 'calc(100% - 32px)' : '525px'}
      elevation="three"
      {...props}
    >
      <Flex flexDirection="row">
        <Flex flexDirection="column">
          <Icon icon={'download'} size={56} color={theme.textColors.med} />
        </Flex>
        <Box width="16px" />
        <Flex flexDirection="column">
          <H2 data-testid="driver-compatible-with">
            {t('apps:compatible-with', { os: convert(os) })}
          </H2>
          <P data-testid="driver-version">
            {t('apps:version', { version: info?.version || '' })}
          </P>
          <Box width={'105px'}>
            <StyledLink
              data-testid="driver-download-button"
              target="_blank"
              href={info?.url}
              onClick={downloadClicked}
              title={t('apps:driver-for', { os })}
              rel="noopener"
            >
              {t('apps:download')}
            </StyledLink>
          </Box>
        </Flex>
      </Flex>
    </StyledCard>
  );
};
