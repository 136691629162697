import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useReduxAction, useReduxSelector } from 'hooks';
import {
  selectAllPrintersOnlineFirst,
  getSelectedPrinter,
} from 'ducks/printers/selectors';
import { actions as templatesActions } from 'ducks/templates/actions';
import { actions as printerActions } from 'ducks/printers/actions';
import { getIsLoadingPrint } from 'ducks/templates/selectors';
import { getSelectedFile } from 'ducks/dataSources/selectors';
import { getRangeSequenceString, parseRangeString } from 'utils/templates';
import { Box, Button, Dropdown, Flex, Form, Icon, Input } from 'components';
import { ThemeContext } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { usePrintDialog } from './PrintDialogContext';
import { useLabelRange } from './LabelRangeContext';
import { TemplateType } from 'zsbpsdk/src/templates';
import { SectionTitle, NumberInputWrapper } from './PrintDialogFooter.styles';
import { ALLOWED_SEPARATORS } from './input/PrintCurrencyNumberInput';
import { openErrorToast } from 'state/Toast';
import { useAppDispatch } from 'hooks';

const MIN_PRINT_COPIES = 1;

type PrintDialogFooterProps = {
  template: TemplateType;
  fields?: any;
  onLabelRangeSelect: () => void;
  onCopiesChange: (copies: number) => void;
  labelPreviewError: any;
  loading: boolean;
};

function selectAllLabels(labelRange: any[], firstRowIsHeader: boolean) {
  const labelRangeLength = labelRange?.length || 0;
  const headerRowCount = firstRowIsHeader ? 1 : 0;
  return Array.from(
    { length: labelRangeLength - headerRowCount },
    (v, i) => i + headerRowCount,
  );
}

const PrintDialogFooter = ({
  template,
  fields,
  onLabelRangeSelect,
  onCopiesChange,
  labelPreviewError,
  loading,
}: PrintDialogFooterProps) => {
  const { t } = useTranslation(['components', 'notifications', 'common']);
  const [
    { selectedLabels, copies, firstRowIsHeader },
    { setSelectedLabels, clearSelectedLabels },
  ] = usePrintDialog();
  const [{ labelRange, labelRangeMapping }] = useLabelRange();
  const LABEL_RANGE_ALL = t('common:range-all');
  const LABEL_RANGE_NONE = t('common:range-none');

  const theme = React.useContext(ThemeContext);
  const selectedPrinter = useReduxSelector(getSelectedPrinter);

  const setSelectedPrinter = useReduxAction(printerActions.SELECTED.set);

  const dispatch = useAppDispatch();

  const [selectedLabelsError, setSelectedLabelsError] = useState<
    Error | undefined
  >();
  const [labelRangeDisplayText, setLabelRangeDisplayText] = useState<string>(
    selectedLabels ? String(selectedLabels) : LABEL_RANGE_NONE,
  );

  const fixSelectedRange = (userTypedRange: any[], realLabelRange: any[]) => {
    let intersection = userTypedRange.map((x) =>
      realLabelRange.indexOf(realLabelRange[x]),
    );
    const labelRange = intersection.filter((element) => element > -1);
    return labelRange;
  };
  const allDataSourceIndexesArray = selectAllLabels(
    labelRange,
    firstRowIsHeader,
  );

  const checkSelectedRange = (parsedLabelRange: number[]) => {
    const selectedLabelsIntersection = parsedLabelRange.filter((value) =>
      allDataSourceIndexesArray.includes(value),
    );
    const allDataRowsSelected =
      JSON.stringify(selectedLabelsIntersection) ===
      JSON.stringify(allDataSourceIndexesArray);

    return allDataRowsSelected;
  };

  const parseLabelRangeDisplayText = useCallback((): void => {
    try {
      clearSelectedLabels();
      setSelectedLabelsError(undefined);

      let parsedLabelRange: number[];

      if (labelRangeDisplayText === LABEL_RANGE_ALL) {
        parsedLabelRange = selectAllLabels(labelRange, firstRowIsHeader);
      } else {
        parsedLabelRange = parseRangeString(labelRangeDisplayText);
      }

      if (Math.max(...parsedLabelRange) >= labelRange.length) {
        parsedLabelRange = fixSelectedRange(parsedLabelRange, labelRange);
      }

      if (parsedLabelRange.length > 0) {
        const allDataRowsSelected = checkSelectedRange(parsedLabelRange);
        // user has typed the full range
        if (allDataRowsSelected) {
          setSelectedLabels(selectAllLabels(labelRange, firstRowIsHeader));
        }
        // user has typed 0 based range, but the 0 row is the header(firstRowIsHeader),
        // so we can not check it
        else if (
          firstRowIsHeader &&
          parsedLabelRange[0] === 0 &&
          parsedLabelRange.length > 1
        ) {
          setSelectedLabels(parsedLabelRange.slice(1, parsedLabelRange.length));
        } else if (
          firstRowIsHeader &&
          parsedLabelRange[0] === 0 &&
          parsedLabelRange.length === 1
        ) {
          setSelectedLabels([1]);
        }
        // user has typed valid range
        else {
          setSelectedLabels(parsedLabelRange);
        }
      } else if (labelRangeDisplayText !== LABEL_RANGE_NONE) {
        setSelectedLabelsError(new Error('Empty'));
      }
    } catch (e: any) {
      setSelectedLabelsError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    labelRangeDisplayText,
    labelRange,
    firstRowIsHeader,
    setSelectedLabels,
    clearSelectedLabels,
    LABEL_RANGE_ALL,
    LABEL_RANGE_NONE,
  ]);

  const printers = useReduxSelector(selectAllPrintersOnlineFirst);

  const [printer, setPrinter] = useState({
    uniqueId: selectedPrinter?.uniqueId,
    cartridgeInfo: {
      remaininglabels: selectedPrinter?.cartridgeInfo?.remaininglabels ?? 0,
    },
  });

  const [printersDropdown, setPrintersDropdown] = useState<
    { value: string; label: string }[]
  >(
    printers.map((p: any) => ({
      value: p.uniqueId,
      label: p.name ? p.name : p.uniqueId + '-' + p.model,
    })),
  );

  useEffect(() => {
    setSelectedPrinter(printer?.uniqueId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printer]);

  useEffect(() => {
    setPrintersDropdown(
      printers.map((p: any) => ({
        value: p.uniqueId,
        label: p.name ? p.name : p.uniqueId + '-' + p.model,
      })),
    );

    if (!printer.uniqueId && printers.length > 0) {
      setPrinter(printers[0]);
    } else {
      printers.some((printerIter) => {
        if (printer.uniqueId === printerIter.uniqueId) {
          setPrinter(printerIter);

          return true;
        }
        return false;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printers]);

  const handleLabel = useCallback(() => {
    if (!labelRangeMapping?.manual) onLabelRangeSelect();
  }, [labelRangeMapping, onLabelRangeSelect]);

  const print = useReduxAction(templatesActions.PRINT.request);
  const clearPrintTemplate = useReduxAction(
    templatesActions.PRINT.clear_template,
  );

  const isLoadingPrint = useReduxSelector(getIsLoadingPrint);
  const selectedFile = useReduxSelector(getSelectedFile);
  const arePrintersAvailable = () =>
    printers.length > 0
      ? t('notifications:widget.printer-offline')
      : t('components:printer.messages.no-printers-available');

  const hasSelectedLabels = useMemo(() => {
    if (selectedFile) {
      if (selectedLabels) {
        return selectedLabels.length > 0 ? true : false;
      } else if (labelRangeDisplayText === LABEL_RANGE_NONE) {
        return false;
      }
    }
    return true;
  }, [selectedFile, selectedLabels, LABEL_RANGE_NONE, labelRangeDisplayText]);

  const isCopyRangeValid = useMemo(
    () => copies > 0 && copies <= 1000,
    [copies],
  );

  useEffect(() => {
    if (selectedLabels != null) {
      const parsedLabelRange = parseRangeString(labelRangeDisplayText);
      const allDataRowsSelected = checkSelectedRange(parsedLabelRange);

      if (parsedLabelRange.length > allDataSourceIndexesArray.length) {
        if (allDataRowsSelected) {
          setLabelRangeDisplayText(LABEL_RANGE_ALL);
        } else {
          const selectedLabelsIntersection = parsedLabelRange.filter((value) =>
            allDataSourceIndexesArray.includes(value),
          );
          const sequenceString = getRangeSequenceString(
            [...selectedLabelsIntersection].sort((a, b) => a - b),
            true,
          );
          setLabelRangeDisplayText(sequenceString);
        }
      } else if (
        labelRange.length ===
        selectedLabels.length + (firstRowIsHeader ? 1 : 0)
      ) {
        setLabelRangeDisplayText(LABEL_RANGE_ALL);
      } else if (selectedLabels.length === 0) {
        setLabelRangeDisplayText(LABEL_RANGE_NONE);
      } else {
        const sequenceString = getRangeSequenceString(
          [...selectedLabels].sort((a, b) => a - b),
          true,
        );
        setLabelRangeDisplayText(sequenceString);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    labelRange,
    firstRowIsHeader,
    selectedLabels,
    LABEL_RANGE_ALL,
    LABEL_RANGE_NONE,
  ]);

  const findNegativeDecrementingCounter = useCallback(() => {
    for (const field of fields) {
      if (
        field.counterType === 'Decrement' &&
        copies > parseInt(field.initialValue) + 1
      ) {
        return true;
      }
    }
    return false;
  }, [fields, copies]);

  const handleSubmit = useCallback(() => {
    clearPrintTemplate();

    // Check if there are "prompt at print" fields: fields.length > 0,
    // if they are such fields - check if they are all empty.
    let allFieldsEmpty;
    if (fields.length > 0) {
      allFieldsEmpty = fields.every((field) => field.initialValue === '');
    }

    if (allFieldsEmpty) {
      dispatch(
        openErrorToast(t('components:printer.messages.print-job-not-sent')),
      );
    } else {
      print({
        template,
        printer,
        fields,
        copies,
        selectedLabels,
        labelRange,
        labelRangeMapping,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clearPrintTemplate,
    print,
    template,
    printer,
    fields,
    copies,
    selectedLabels,
    labelRange,
    labelRangeMapping,
  ]);

  const onChangeCopiesHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { target } = event;
      const { value: v } = target;
      if (!Number.isInteger(+v)) {
        return;
      }
      const updatedCopies =
        Number(v) > MIN_PRINT_COPIES ? Number(v) : MIN_PRINT_COPIES;
      onCopiesChange(updatedCopies);
      if (updatedCopies === MIN_PRINT_COPIES) {
        selectInputValue(target as HTMLInputElement);
      }
    },
    [onCopiesChange],
  );

  const onClickCopiesHandler = useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      selectInputValue(event.target as HTMLInputElement);
    },
    [],
  );

  const selectInputValue = (target: HTMLInputElement) => {
    setTimeout(() => {
      //setTimeout to ensure selecting value AFTER onChange event has completed
      target.select();
    }, 0);
  };

  return (
    <Flex flexDirection={'row'}>
      <Box width={'100%'}>
        <Flex alignItems={'flex-start'} flexDirection={'row'}>
          <Box mr={24}>
            <SectionTitle data-testid="print-options-footer-labels-left" mb={2}>
              {!selectedPrinter?.status?.isOnline
                ? arePrintersAvailable()
                : t('components:printer.labels-left', {
                    count: printer?.cartridgeInfo?.remaininglabels || 0,
                  })}
            </SectionTitle>
            <Dropdown
              placeholder={t('components:printer.select-printer')}
              data-testid="print-options-footer-printer-dropdown"
              value={printer?.uniqueId}
              showTop={true}
              onChange={(e) => {
                if (e) {
                  setPrinter(
                    printers.find((printer) => printer.uniqueId === e),
                  );
                } else {
                  setPrinter({
                    uniqueId: undefined,
                    cartridgeInfo: { remaininglabels: 0 },
                  });
                }
              }}
              options={printersDropdown}
            />
          </Box>

          {selectedFile && (
            <Box>
              <SectionTitle mb={2}>
                {t('components:printer.label-range', {
                  file: selectedFile?.fileName,
                })}
              </SectionTitle>
              <Input
                data-testid="print-options-step-section-input"
                value={labelRangeDisplayText}
                disabled={labelRangeMapping?.manual}
                iconEnd={
                  <Icon icon={'select-range'} color={theme.textColors.med} />
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setLabelRangeDisplayText(e.target.value);
                }}
                hasError={selectedLabelsError != null}
                onBlur={parseLabelRangeDisplayText}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    parseLabelRangeDisplayText();
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                onEndIconClick={handleLabel}
              />
            </Box>
          )}
        </Flex>
      </Box>
      <Box width={'100%'}>
        <Flex alignItems={'flex-end'} justifyContent={'flex-end'}>
          <Box>
            <SectionTitle mb={2}>{t('components:quantity')}</SectionTitle>
            <NumberInputWrapper>
              <Input
                textAlign={'center'}
                type={'number'}
                value={String(copies)}
                inputMode={'numeric'}
                pattern={'[0-9]{10}'}
                onClick={onClickCopiesHandler}
                onChange={onChangeCopiesHandler}
                onKeyDown={(event) => {
                  if (ALLOWED_SEPARATORS.includes(event.key))
                    event.preventDefault();
                }}
              />
            </NumberInputWrapper>
          </Box>
          <Box ml={11}>
            <SectionTitle mb={2} data-testid="total-label-text">{`Total of ${
              (selectedLabels != null && selectedLabels.length > 0
                ? selectedLabels.length
                : 1) * copies
            } Labels`}</SectionTitle>
            <Form onSubmit={handleSubmit}>
              <Button
                size={'xl'}
                data-testid="done-button"
                disabled={
                  isCopyRangeValid &&
                  !findNegativeDecrementingCounter() &&
                  !labelPreviewError &&
                  Number.isInteger(copies) &&
                  printer?.uniqueId &&
                  hasSelectedLabels &&
                  !selectedLabelsError &&
                  !loading
                    ? false
                    : true
                }
                isLoading={isLoadingPrint}
                type={'submit'}
                variant={'primary'}
              >
                {isLoadingPrint
                  ? t('components:printer.printing')
                  : t('components:printer.print')}
              </Button>
            </Form>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};
export default PrintDialogFooter;
