import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Flex } from 'components';
import { Text } from 'v2/components/common';
import { getPreferencesLanguage } from 'utils/i18n';
import { BannerMessage } from 'zsbpsdk/proto/ZsbpPortalService_pb';

import {
  getBannerMessageContentForLanguage,
  incrementExpirableBannerMessageInfo,
} from 'utils/bannerMessages';
import { getCustomer } from 'ducks/auth/selectors';
import { useReduxSelector } from 'hooks';

type AnnouncementWrapType = { shown: boolean };

const AnnouncementWrap = styled(Flex)<AnnouncementWrapType>`
  transition: opacity 0.75s linear;
  width: 100%;
  position: absolute;
  z-index: 99;
  color: white;
  padding: ${({ theme }) => theme.px(0, 10)};
  background-color: ${({ theme }) => theme.primary.base};
  font-size: 1rem;
  height: 32px;
  line-height: 32px;
  min-width: 0;
  justify-content: center;
  opacity: ${({ shown }) => (shown ? '1' : '0')};

  & span {
    color: ${({ theme }) => theme.white};
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSizes.body}px;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const Link = styled.a`
  flex-shrink: 0;
  margin-left: 6px;
  &,
  &:visited {
    color: ${({ theme }) => theme.white};
  }
`;

export const StyledText = styled(Text).attrs({ variant: 'h2' })`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

type AnnouncementType = {
  bannerMessages: BannerMessage[];
  onModalOpen: (bannerMessage: BannerMessage.Content) => void;
};

const Announcement = ({ bannerMessages, onModalOpen }: AnnouncementType) => {
  const { i18n } = useTranslation('common');

  const [nextBannerMessageContent, setNextBannerMessageContent] = useState<
    BannerMessage.Content | undefined
  >(undefined);
  const [currentBannerMessageContent, setCurrentBannerMessageContent] =
    useState<BannerMessage.Content | undefined>(undefined);
  const [shown, setShown] = useState(true);

  const [currentBannerID, setCurrentBannerID] = useState<number | undefined>(
    undefined,
  );
  const currentCustomer = useReduxSelector(getCustomer);

  const handleLinkClick = useCallback(() => {
    if (!currentBannerMessageContent) return;

    const linkUrl = currentBannerMessageContent.getLinkurl().trim();

    if (linkUrl) {
      window.open(linkUrl);

      return;
    }

    onModalOpen(currentBannerMessageContent);
  }, [onModalOpen, currentBannerMessageContent]);

  useEffect(() => {
    let interval;
    let index = 0;
    const bannerMessagesLength = bannerMessages.length;

    if (bannerMessagesLength === 0) {
      setCurrentBannerMessageContent(undefined);
      return;
    }

    setCurrentBannerMessageContent(
      getBannerMessageContentForLanguage(
        bannerMessages[index],
        getPreferencesLanguage(i18n),
      ),
    );

    if (bannerMessagesLength === 1) return;

    interval = setInterval(() => {
      if (index >= bannerMessagesLength - 1) {
        index = 0;
      } else {
        index++;
      }

      setShown(false);
      setCurrentBannerID(bannerMessages[index].getId());

      setNextBannerMessageContent(
        getBannerMessageContentForLanguage(
          bannerMessages[index],
          getPreferencesLanguage(i18n),
        ),
      );
    }, 10000);

    return () => clearInterval(interval);
  }, [bannerMessages, i18n]);

  const handleTransitionEnd = useCallback(() => {
    if (!shown) {
      incrementExpirableBannerMessageInfo(currentCustomer, currentBannerID!);
      setCurrentBannerMessageContent(nextBannerMessageContent);
      setShown(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextBannerMessageContent, shown]);

  return (
    <>
      {currentBannerMessageContent && (
        <AnnouncementWrap shown={shown} onTransitionEnd={handleTransitionEnd}>
          <StyledText variant="h2">
            {currentBannerMessageContent.getMessagetext()}
          </StyledText>

          <Link href="#!" onClick={handleLinkClick}>
            {currentBannerMessageContent.getLinktext()}
          </Link>
        </AnnouncementWrap>
      )}
    </>
  );
};

export default Announcement;
