import { DataSourceType } from './dataSourcesTypes';
import { TemplateType } from 'zsbpsdk/src/templates/index';
import { LabelStock, TemplateCategory } from './categories';

export const MODIFIABLE_DATA_FIELDS: DataSourceType[] = [
  DataSourceType.Date,
  DataSourceType.Currency,
  DataSourceType.Number,
  DataSourceType.Picture,
  DataSourceType.Text,
];

export const UNMODIFIABLE_DATA_FIELDS: DataSourceType[] = [
  DataSourceType.ClientDate,
  DataSourceType.ClientTime,
  DataSourceType.Counter,
];

export const SETTABLE_DATA_FIELDS: DataSourceType[] = [
  ...MODIFIABLE_DATA_FIELDS,
  ...UNMODIFIABLE_DATA_FIELDS,
];

export const getTemplateCategory = (
  tenant: string,
  category: LabelStock | undefined,
): TemplateCategory | LabelStock =>
  tenant === TemplateCategory.Internal
    ? category
      ? category
      : TemplateCategory.Internal
    : TemplateCategory.UserCreated;

/**
 * Gets the Range string of the numbers
 * ie: initial and end numbers of a sequence
 * @param numbers Array of numbers
 * @return string
 */
export const getRangeSequenceString = (
  numbers: number[],
  isFirstRowHeader: boolean = false,
): string => {
  if (numbers?.length === 0) {
    return '';
  }
  if (numbers?.length === 1) {
    return (numbers[0] + (isFirstRowHeader ? 0 : 1)).toString();
  } else {
    const ranges: any[] = [];

    let temp: { init: number; end: number | null } = {
      init: numbers[0] + (isFirstRowHeader ? 0 : 1),
      end: null,
    };
    let count: number = 0;

    for (let i = 1; i < numbers?.length; i++) {
      if (i > 0 && numbers[i] === numbers[i - 1] + 1) {
        count++;
      } else {
        temp.end = temp.init + count;
        ranges.push(temp);
        count = 0;
        temp = {
          init: numbers[i] + (isFirstRowHeader ? 0 : 1),
          end: null,
        };
      }
    }
    temp.end = temp.init + count;
    ranges.push(temp);
    let labelString = '';
    ranges.forEach((range) => {
      range.init === range.end
        ? (labelString += ` ${range.init},`)
        : (labelString += ` ${range.init}-${range.end},`);
    });
    return labelString.slice(0, -1).trim();
  }
};

export const parseRangeString = (str): number[] => {
  let strArr = str
    .replace(/\s+/g, '')
    .replace(/(\s*[-|,]\s*)+/g, '$1')
    .split('');

  //Reduce creates clean array of: numbers , -
  strArr = strArr.reduce((acc, val, idx) => {
    if (val === ',' || val === '-' || !isNaN(val)) {
      if (acc.length === 0) {
        if (!isNaN(val)) {
          //Return first numeric character
          return [val];
        }
      } else {
        if (!isNaN(val)) {
          // If value number

          if (!isNaN(acc[acc.length - 1])) {
            // If previous value is number
            let x1 = acc.pop();
            //Combine numeric digits into single number
            acc.push('' + x1 + val);
            return acc;
          } else {
            //Add single digit to array
            acc.push(val);
          }
        } else {
          //Add - , to array
          acc.push(val);
        }
      }
    }
    return acc;
  }, []);

  return [...new Set(createNumberRange(strArr).sort((a, b) => a - b))];
};

const createNumberRange = (rangeStringArray: string[]): number[] => {
  const numberRangeArray: number[] = [];
  const cachedArrayLength = rangeStringArray.length;

  for (let i = 0; i < cachedArrayLength; i++) {
    const currentElement = rangeStringArray[i];
    const previousElement = rangeStringArray[i - 1];
    const nextElement = rangeStringArray[i + 1];

    if (currentElement === ',') {
      //If current element is , push the previous number element

      numberRangeArray.push(+previousElement);
    } else if (currentElement === '-') {
      //If current element is - create an array of values between the previous and next values and spread it

      const pickedRangeArray = Array.from(
        { length: +nextElement - +previousElement + 1 },
        (_, idx) => +previousElement + idx,
      );

      numberRangeArray.push(...pickedRangeArray);
    } else {
      //Else just push the value

      numberRangeArray.push(+currentElement);
    }
  }

  return numberRangeArray;
};

type RichDatasource = { label: string; value: string; selected: boolean };
export type RichDatasources = RichDatasource[];

export const parseInitialValue = (
  initialValue: RichDatasources | string = '',
): string => {
  if (Array.isArray(initialValue)) {
    const found = initialValue.find((o: RichDatasource) => o.selected === true);
    if (found && found.value) {
      return found.value;
    }
  } else if (typeof initialValue === 'string') {
    return initialValue;
  }
  return '';
};

export const getSizesFromTemplates = (templates: TemplateType[]) =>
  Object.values(
    templates.reduce((acc: any, { labelSize }: any) => {
      acc[`${labelSize.width}x${labelSize.height}`] = labelSize;
      return acc;
    }, {}),
  );
